<template>
  <form class="auth-form" @submit.prevent="resetPassword">
    <h3 class="auth-title">
      <button class="btn" type="button" @click="back()">
        <span class="material-icons-outlined">arrow_back</span>
      </button>
      Redefinir
    </h3>
    <div class="form-group">
      <!-- <label for="inputEmail" class="">{{$t('login-invite.email-address')}}</label> -->
      <label for="inputEmail" class="">{{$t('reset-password.email')}}</label>
      <input
        v-model="form.email"
        type="email"
        id="inputEmail"
        class="form-control"
        placeholder="Email"
        :disabled="isSending"
        required />
    </div>
    <button
      type="submit"
      class="btn btn-yup-purple btn-block"
      :class="{ 'btn-block-ui': isSending }"
      :disabled="isSending || !valid"
    >
      {{ $t('reset-password.reset') }}
    </button>
  </form>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Login',
  computed: {
    valid() {
      const email = this.form?.email;
      return email && /^[^@\s]+[@][^@\s]+$/.test(email);
    },
  },
  data() {
    return {
      isSending: false,
      form: {
        email: '',
      },
    };
  },
  methods: {
    back() {
      this.$router.back();
    },
    resetPassword() {
      this.isSending = true;
      this.$store.dispatch('auth/reset', this.form).then(
        () => {
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('warnings.reset-password.instruction'),
            type: 'success',
          });
          this.isSending = false;
        },
        (error) => {
          this.$toast.show({
            title: this.$t('sms.infos.filters.status.lbl-error'),
            content: this.$t('warnings.error-occur'),
            type: 'danger',
          });
          console.log(error);
          this.isSending = false;
        },
      );
    },
  },
};
</script>
<style lang="scss">
.sign-in-form {
  position: relative;
  background-color: var(--background);
  z-index: 1;
  box-shadow: 0px 4px 6px #00000029;
  padding: 70px;
}
.to_login {
  color: #bfc5cb;
  font-size: 20px;
  border-radius: 50%;
  padding: 5px;
  transition: 0.5s;

  &:hover {
    color: var(--clr-yup-purple-hover);
    background-color: var(--clr-yup-purple-hover-transparent);
  }

  &:focus {
    color: var(--clr-yup-purple-hover);
    background-color: var(--clr-yup-purple-hover-transparent);
  }
}
@media (max-width: 767.98px) {
  .sign-in-form {
    top: 20%;
  }
}
</style>
